<template>
  <div class="flex flex-col">

    <p>Jeg godkjenner Smart Retur sine <a class="text-green-500 underline" href="https://www.smartretur.no/uploads/Smart-Retur-std-avtale-2024-norsk.pdf" target="_blank">generelle standardvilkår</a> og bekrefter at jeg har fullmakt til å signere denne. </p>
    <p class="mt-2">Send forespørsel til support@smartretur.no eller ring oss på telefon +47 975 67 000 dersom du har noen spørsmål.</p>

    <div class="flex flex-col-reverse items-center justify-between flex-1 gap-4 mt-12 md:flex-row md:space-y-0" >

      <a
        class="inline-block mt-4 text-sm underline md:mt-0"
        href
        @click.prevent="onReject"
        v-html="$tk('Agreement.Reject')"
      ></a>

      <div class="flex items-center mt-4 space-x-4">

        <PButton
          :loading="isSubmitting"
          color="primary"
          @click="onAccept"
        >
          <span v-html="$tk('Agreement.Accept')"></span>
        </PButton>

      </div>

    </div>

  </div>
</template>

<script>

import http from "@/utilities/http"

export default {

  name: "agreement",

  data() {
    return {
      isSubmitting: false,
    }
  },

  methods: {

    handleError () {
      alert(this.$tk("Gdpr.AnUnexpectedErrorOccuredPleaseTryAgain", true))
    },

    async onReject () {
      this.$router.push({ name: "home" })
    },

    async onAccept () {
      this.isSubmitting = true
      await this.submit(true)
      this.$router.push({ name: "app" })
      this.isSubmitting = false
    },

    async submit (accept) {
      try {
        await http.post("customerAgreement", { accept })
      } catch {
        this.handleError()
      }
    }

  },

}
</script>
